<template>
	<div class="page">
		<div class="page-title">
			<div class="txt">添加估价对象</div>
			<Icon type="ios-redo" @click="back" />
		</div>
		<div class="ct">
			<div class="left">
				<div class="time">
					<div class="dot">1</div>
					<div class="line"></div>
					<div class="dot">2</div>
					<div class="line"></div>
				</div>
				<div class="info-box">
					<div class="box">
						<div class="title">步骤一：下载待估价对象数据模板</div>
						<div class="download">
							<div class="icon"></div>
							<div class="txt">待估价对象数据模板.xlsx</div>
							<div class="btn" @click="download">下载</div>
						</div>
						<div class="info-txt">
							<p>注：</p>
							<p>
								•
								请按照待估价对象数据模板添加估价对象，标有“必填”的字段必须填写，否则无法估价；
							</p>
							<p>• 估价对象信息越详尽，估价结果越精确；</p>
							<p>
								•
								目前智能估价系统可以估价住宅价值，估价对象主要指住宅，商业、办公等尚未开放。
							</p>
						</div>
					</div>
					<div class="box">
						<div class="title">步骤二：上传待估价对象数据</div>
						<label class="upload" for="uploadInput">
							<div class="name" v-if="formItem.file">
								{{ formItem.file.name }}
							</div>
							<template v-else>
								<div class="icon"></div>
								上传待估价对象数据文件
							</template>
							<input id="uploadInput" type="file" :accept="inputType" @change="inputChange" />
						</label>
						<Form v-model="formItem">
							<FormItem label="任务名称">
								<Input clearable filterable type="text" v-model="formItem.taskName" placeholder="任务名称"
									style="width: 300px" />
							</FormItem>
							<FormItem label="估价基准日">
								<DatePicker placeholder="估价基准日" type="date" :options="dateOpts"
									v-model="formItem.initialEvalDate" @on-change="changeTime($event)"
									style="width: 300px"></DatePicker>

							</FormItem>
							<FormItem>
								{{marking}}
							</FormItem>
						</Form>

						<div class="tips">
							注：相同证载地址的估价对象只能提交一次，如果上传的估价对象与已经上传的估价对象存在相同的证载地址，或同一任务中有相同的证载地址，系统不会对相同的估价对象进行重复估价，任务中只保留最早上传的估价对象，如需查询估价结果请在估价查询中进行查询。
						</div>

						<div class="btn-submit" @click="showSubmitBounce">提交任务</div>
					</div>
				</div>
			</div>
			<div class="right">
				<p>字段说明：</p>
				<p>1、估价对象编号：整数形式的序号；</p>
				<p>2、省份：房屋所在省份；</p>
				<p>
					3、城市：城市中文名称，例如北京市（需带“市”），
					<span class="red">为必填项</span>；
				</p>
				<p>4、区县：房屋所在区县，如天河区；</p>
				<p>
					5、证载地址：不动产权证所载明的房屋地址，
					<span class="red">为必填项</span>；
				</p>
				<p>6、小区名称：房屋隶属小区名称；</p>
				<p>
					7、面积：整数或者浮点数，单位为平方米，
					<span class="red">为必填项</span>；
				</p>
				<p>8、物业类型：为枚举值，住宅、别墅；</p>
				<p>9、所在楼层、总楼层：为整数，且所在楼层≤总楼层；</p>
				<p>10、房屋户型：为枚举值，格式N房N厅N厨N卫，如四房两厅一厨两卫；</p>
				<p>
					11、朝向：为枚举值，东、南、西、北、东南、东北、西南、西北、东西、南北；
				</p>
				<p>12、建成年代：整数类型，如2012；</p>
				<p>
					13、户型结构：为枚举值，住宅：平层、复式、跃层、错层、loft；别墅：叠拼、独栋、联排、双拼；
				</p>
				<p>
					14、贷款抵押额度是指银行根据抵押物价值和抵押率高低确定出来的可以贷款的金额；
				</p>
				<p>15、抵押银行是指签订抵押贷款合同的银行；</p>
				<p>16、抵押日期是指押品在银行进行抵押的日期或银行抵押合同签订日期；</p>
				<p>
					17、预警值为事先设定的风险控制值，当押品估值低于该值是系统将自动提示风险；正常预警：估值>预警值*110%；黄色预警：预警值*105%≤估值≤预警值*110%；橙色预警：预警值＜估值≤预警值*105%；红色预警：估值≤预警值；
				</p>
				<p>18、最高抵质押率为该房屋设定的可以容忍的最高抵质押率；</p>
				<p>
					19、最高可用担保额度=押品估值*最高抵质押率-贷款抵押额度（或押品初始估值*70%）；
				</p>
				<p>
					20、估价基准日：日期类型，如“2017-05-02”，如果不填，默认取当前日期；要求同一批上传的数据估价基准日必须相同，否则参数报错。
				</p>
			</div>
		</div>
		<Spin size="large" fix v-if="loading"></Spin>
		<Modal v-model="showSubmit" :closable="false" style="margin-top:20px">
			<p slot="header" style="text-align: center">
				<span>温馨提示</span>
			</p>
			<p>上传待估价对象数据文件中填写<span style="color: red">贷款抵押额度</span>数据后，用户可以在管理界面按贷款抵押额度设置<span
					style="color: red">预警值</span>和<span style="color: red">抵质押率</span>，确定上传后<span
					style="color: red">不能</span>重新填写，是否提交任务？</p>
			<div class="modal-footer" style="display: flex; justify-content: center" slot="footer">
				<Button :loading='submitLoading' type="primary" @click="submit">确定</Button>
				<Button @click="hideModal">取消</Button>
			</div>
		</Modal>
	</div>
</template>

<script>
	// @ is an alias to /src

	import {
		getDownloadTemplate,
		add
	} from "@/api/pledge";
	import {
		formatDate
	} from '@/utils/index';

	import {
		setTimeout
	} from "timers";

	export default {
		name: "taskManage",
		components: {},
		data() {
			return {
				submitLoading: false,
				formItem: {
					file: "",
					taskName: "",
					initialEvalDate: new Date(),
				},
				inputType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
				loading: false,
				lastHalfYear: formatDate(Date.parse(new Date()) - 182 * 24 * 60 * 60 * 1000),
				showSubmit: false,
				//设置日期不可选
				dateOpts: {
					disabledDate(date) {
						return date.valueOf() < Date.now() - 183 * 24 * 60 * 60 * 1000 || date.valueOf() > new Date()
							.setDate(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate());
					}
				}
			};
		},
		computed: {
			marking() {
				return `注：估价基准日需要设置在近半年以内，即可选范围为：${this.lastHalfYear}至本月末。`
			}
		},
		beforeMount() {
			this.$Notice.destroy();
		},
		mounted() {
			// if (this.$route.params._token != "taskManage") {
			//     this.$router.push({
			//         path: "/Empowerment/pledge/pledgeManage/taskManage",
			//     });
			// }
			this.info1();
		},
		destroy() {
			this.$Notice.destroy();
		},
		methods: {
			hideModal() {
				this.showSubmit = false
			},
			info1() {

			},
			info2() {
				let _this = this;


				// setTimeout(() => {
				//   _this.info3();
				// }, 8000);
			},
			// info3() {
			//   this.$msg.error({ type: "tips", code: 30423 });
			// },
			back() {
				this.$router.go(-1);
			},
			download() {
				this.info2();
				getDownloadTemplate().then((res) => {
					setTimeout(() => {
						window.open(res.url, "_blank");
					}, 2000);
				});
			},
			inputChange(event) {
				let file = event.target.files[0];
				if (file.type != this.inputType) {
					// this.$Message.warning("请上传正确的模板");

					this.$msg.error({
						type: "remind",
						code: 4038
					});

					return;
				}
				if (file) {
					this.formItem.file = file;
				}
			},
			changeTime(e) {
				this.formItem.initialEvalDate = e;
			},
			showSubmitBounce() {
				if (this.loading) return;
				let formItem = this.formItem;
				if (!formItem.file) {
					// this.$Message.warning("请上传数据文档");
					this.$msg.error({
						type: "remind",
						code: 4038
					});
					return;
				}
				if (!formItem.taskName) {
					// this.$Message.warning("请输入任务名称");
					this.$msg.error({
						text: ["请输入任务名称"]
					});
					return;
				}
				if (!formItem.initialEvalDate) {
					// this.$Message.warning("请选择估价基准日");
					this.$msg.error({
						text: ["请选择估价基准日"]
					});
					return;
				} else {
					//估价基准日限制在最近半年
					let today = Date.parse(new Date());
					let pass = new Date(formItem.initialEvalDate).getTime();
					let gap = today - pass > 183 * 24 * 60 * 60 * 1000;
					if (gap) {
						this.$msg.error({
							type: "remind",
							code: 4052,
							to: "/Customization/CustomizationServe",
						});
						return;
					}

				}

				//判断任务名是否重复
				if (this.$route.params.data) {
					let nameCheck = this.$route.params.data.find((i) => {
						return i.taskName == formItem.taskName;
					});
					if (nameCheck) {
						this.$msg.success({
							text: ["*任务名称重复"]
						});
						return;
					}
				}
				this.showSubmit = true

			},
			submit() {
				let time = this.formItem.initialEvalDate;
				let formItem = this.formItem;
				if (typeof time == "object") {
					const myDate = new Date(time);
					const year = myDate.getFullYear(); // 获取当前年份
					const month = myDate.getMonth() + 1; // 获取当前月份(0-11,0代表1月所以要加1);
					const day = myDate.getDate(); // 获取当前日（1-31）
					time = `${year}-${month < 10 ? "0" + month : month}-${
          day < 10 ? "0" + day : day
        }`;
				}
				this.loading = true;
				this.submitLoading = true
				let formData = new FormData();
				formData.append("file", formItem.file);
				formData.append("taskName", formItem.taskName);
				formData.append("initialEvalDate", time);
				add(formData)
					.then((res) => {
						// this.$msg.success("任务配置成功");
						this.showSubmit = false
						this.$msg.error({
							type: "remind",
							code: 4045
						});

						this.formItem.taskName = "";
						setTimeout(() => {
							this.$router.push({
								path: "/Empowerment/pledge/pledgeManage/taskManage",
							});

						}, 2000);
					})
					.catch((e) => {
						if (e.code != -1) {
							this.$msg.error({
								type: "fromEnd",
								code: e.code,
								timeout: 0
							});

							this.formItem.taskName = "";
						} else {
							this.$msg.error({
								text: e.data
							});
							this.formItem.taskName = "";
						}
						this.showSubmit = false
					})
					.finally(() => {
						this.loading = false;
						this.submitLoading = false

					});
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import "@/style/common.scss";

	.page {
		@include flex(flex-start, flex-start);
		flex-direction: column;
		height: 100%;
		min-width: 1200px;
		padding: 20px;
		font-size: 14px;

		.page-title {
			@include flex;
			width: 100%;
			margin-bottom: 40px;
			color: #333;
			font-size: 20px;
			font-weight: bold;

			.ivu-icon-ios-redo {
				margin-left: auto;
				color: #00b6ff;
				font-size: 36px;
				transform: rotateY(180deg);
				cursor: pointer;
			}
		}

		.ct {
			@include flex(flex-start, flex-start);
		}

		.left {
			@include flex(flex-start, flex-start);
			margin-right: 60px;

			.time {
				@include flex;
				flex-direction: column;
				margin-right: 20px;

				.dot {
					@include flex;
					width: 40px;
					height: 40px;
					color: #999;
					font-size: 20px;
					border-radius: 50%;
					border: 1px solid #d6d7d9;
				}

				.line {
					width: 1px;
					height: 233px;
					margin: 10px 0;
					background-color: #d6d7d9;
				}
			}

			.tips {
				margin-top: 50px;
				color: red;
			}

			.info-box {

				// overflow: hidden;
				.title {
					margin-top: 5px;
					margin-bottom: 20px;
					color: #333;
					font-size: 20px;
					font-weight: bold;
				}

				.download {
					@include flex;
					width: 100%;
					height: 60px;
					padding: 0 20px;
					background-color: #f3f7ff;
					cursor: pointer;

					.icon {
						@include bgSrc("pledge/excel.png");
						width: 24px;
						height: 24px;
						margin-right: 10px;
					}

					.txt {
						margin-right: auto;
					}

					.btn {
						@include flex;
						width: 50px;
						height: 30px;
						color: #fff;
						border-radius: 2px;
						background-color: #00b6ff;
					}
				}

				.info-txt {
					margin-top: 15px;
					margin-bottom: 58px;
					color: #666;
					line-height: 2;
					white-space: nowrap;
				}

				.upload {
					@include flex;
					width: 100%;
					height: 60px;
					padding: 0 20px;
					margin-bottom: 20px;
					color: #00b6ff;
					background-color: #f3f7ff;
					cursor: pointer;

					input {
						display: none;
					}

					.icon {
						@include bgSrc("pledge/upload-icon.png");
						width: 20px;
						height: 20px;
						margin-right: 5px;
					}
				}

				/deep/ .ivu-form-item-content {
					display: inline-block;
					margin-left: 0 !important;
				}
			}

			.btn-submit {
				@include flex;
				width: 144px;
				height: 40px;
				margin: 30px auto 0;
				color: #fff;
				font-size: 18px;
				border-radius: 5px;
				background: rgba(0, 182, 255, 1);
				cursor: pointer;
			}
		}

		.right {
			flex-shrink: 0;
			width: 600px;
			padding: 20px;
			color: #666;
			font-size: 14px;
			line-height: 2;
			background-color: #f3f7ff;

			.red {
				color: red;
			}
		}

	}

	/deep/ .ivu-modal-body {
		padding: 40px !important;
	}

	/deep/.ivu-modal {
		margin-top: 180px;
	}
</style>
